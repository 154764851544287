import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';

import {
    IonApp,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
} from '@ionic/react';

import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { Title } from '../../components/Title';

import { useHistory } from "react-router-dom";

import { deviceType } from 'react-device-detect';

function PageExpectations() {
    const history = useHistory();

    function toHome() {
        history.push('/')
	}

    return (
        <IonPage fullscreen={true} id="main-content">
            <IonHeader>
                <IonToolbar style={{ paddingLeft: deviceType=="mobile"? '12px':'2rem' }}>
                    <IonButtons slot={deviceType=="mobile"? "start":"end"} style={{ paddingRight: '2rem' }}>
                        <IonButton onClick={() => (toHome())}>Besson Orthodontics</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent style={{ height: '100%' }}>


                <IonGrid style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    maxWidth: '80rem'
                }}>
                    <Section>
                        What to Expect
                    </Section>

                    <Title>
                    New Patient Appointment
                    </Title>

                    <Paragraph>
                    The first complimentary visit to our office will be for the purpose of a general introduction to our
                    office, as well as, an orthodontic evaluation.  Dr. Besson will give you his opinion as to whether
                    orthodontic treatment is indicated, and when it should be initiated. Some patients are started at an
                    earlier age to either minimize a more severe malocclusion from developing, or to improve an
                    awkward growth pattern.  
                    </Paragraph>

                    <Paragraph>
                    If treatment is not needed at this time, but may be needed in the future,
                    your child will be placed on a recall schedule.  This allows us to monitor growth and dental
                    development, and determine when treatment should begin.  If it appears that treatment is needed
                    and should start at this time, full diagnostic records will be scheduled for the next visit.
                    </Paragraph>

                    <Section>
                    Diagnostic Records Appointment
                    </Section>

                    <Paragraph>
                    Impressions, X-rays, photographs of the teeth and facial structure are taken during this visit.  Dr.
                    Besson will then study and evaluate the diagnostic records and develop what he feels is the most
                    effective treatment plan. There typically is a charge for this comprehensive visit.
                    </Paragraph>

                    <Section>
                    Case Presentation
                    </Section>

                    <Paragraph>
                    Dr. Besson will, after evaluating the diagnostic records, present to you his recommendations for
                    treatment.  Many times there may be a number of different plans presented, and your input will be
                    helpful in finalizing the course of treatment.  The estimated length of treatment will be given and the
                    financial arrangement will be discussed. 
                    </Paragraph>

                    <Paragraph>
                    Orthodontic treatment is a family affair and we would like
                    you to enjoy and understand the wonderful benefits and changes as they become apparent during
                    the treatment period.  We will answer all of your questions regarding any problems that may
                    influence the acceptance of treatment.  We want the patient to have a positive experience, while
                    achieving the best possible result.
                    </Paragraph>

                    <Section>
                    Appointment Scheduling
                    </Section>

                    <Paragraph>
                    Braces will be placed on the teeth during the bonding appointment.  This is scheduled in the morning
                    or early afternoon. Comprehensive instructions are given at the end of this visit.  During the rest of
                    the treatment period, the patient will be visiting our office every 3 to 8 weeks.  Appointments
                    generally range from 15 - 45 minutes.  The longer visits are scheduled in the morning or
                    early afternoon, the shorter appointments occur all day long.
                    </Paragraph>

                    <Paragraph>
                    We do everything possible to eliminate or avoid apprehension.  Most of the treatment
                    visits are in an open setting.  This type of setting lends itself to a very relaxed
                    atmosphere, and we strive to maintain this level at all times.  We feel it is most helpful
                    for the patient to know in advance of their first visit that we practice only orthodontics.
                      General dentistry is not performed here.  There are no extractions, needles, or cavities
                    filled here.  The continuing services of your family dentist will be necessary throughout
                    the course of treatment.
                    </Paragraph>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default PageExpectations;
