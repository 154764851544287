import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';

import {
    IonApp,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
} from '@ionic/react';

import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';

import { useHistory } from "react-router-dom";

import { deviceType } from 'react-device-detect';

function PagePayment() {
    const history = useHistory();

    function toHome() {
        history.push('/')
	}

    return (
        <IonPage fullscreen={true} id="main-content">
            <IonHeader>
                <IonToolbar style={{ paddingLeft: deviceType=="mobile"? '12px':'2rem' }}>
                    <IonButtons slot={deviceType=="mobile"? "start":"end"} style={{ paddingRight: '2rem' }}>
                        <IonButton onClick={() => (toHome())}>Besson Orthodontics</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent style={{ height: '100%' }}>


                <IonGrid style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    maxWidth: '80rem'
                }}>
                    <Section>
                    Insurance & Payment
                    </Section>

                    <Paragraph>
                    We make paying for orthodontic treatment surprisingly easy.  The cost of treatment is clearly 
                    presented to you by Dr. Besson after the records appointment, so you will know exactly what is involved.  
                    Typically, there is a down payment when treatment begins, and the balance is then spread out into monthly payments.  
                    If you have orthodontic insurance, we will fill out all the paperwork involved, and work with your carrier to 
                    ensure that you receive your full benefit.
                    </Paragraph>

                    <Paragraph>
                    Orthodontic insurance is very different than medical insurance. Typically, the orthodontic
                    benefit amount is in the $1,000 - $2,000 range for the lifetime of the covered individual. Most
                    plans will provide that coverage whether you go to an Orthodontist who is “in network”, or
                    “out of network”. While Dr. Besson is not “in network” with any insurance plans, we will work
                    with all the insurance companies, and fill out all the appropriate forms to make sure you
                    receive your maximum benefit. 
                    </Paragraph>

                    <Paragraph>
                    If you have a plan that will only provide coverage for “in
                    network” Orthodontists, we always suggest you get a complimentary opinion as to what
                    treatment Dr. Besson would recommend, in addition to an estimation of the costs involved.
                    You can then go to an “in network” Orthodontist and get a cost estimate from them. 
                    </Paragraph>

                    <Paragraph>
                    Many times patients with these type of restrictive plans find that the “in network” Orthodontist
                    presents a treatment plan that consists of multiple phases of orthodontic treatment which
                    results in an overall treatment cost considerably more expensive than what Dr. Besson would
                    recommend. So, in effect you go to an “in network” Orthodontist to use your $1,000 benefit
                    only to be charged many thousands of dollars more than treatment would have cost with Dr.
                    Besson.
                    </Paragraph>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default PagePayment;
