import React, { useEffect } from 'react';

import { IonApp, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import PageMain from './PageMain/PageMain';
import PagePractice from './PageOther/PagePractice';
import PageExpectations from './PageOther/PageExpectations';
import PagePayment from './PageOther/PagePayment';

// https://daily-dev-tips.com/posts/ionic-tab-bar-circle-middle-button/
const App = () => {
    return (
        <IonApp className="main-app">
            <IonReactRouter>
                {/* <IonTabs> */}
                <IonRouterOutlet animated={false}>
                    <Route exact={true} path="/payment-and-insurance">
                        <PagePayment />
                    </Route>
                    <Route exact={true} path="/what-to-expect">
                        <PageExpectations />
                    </Route>
                    <Route exact={true} path="/practice">
                        <PagePractice />
                    </Route>
                    <Route exact={true} path="/">
                        <PageMain />
                    </Route>

                    {/* <Route exact={true} path="/profile">
                        <ViewProfile />
                    </Route>
                    <Route exact={true} path="/settings">
                        <ViewSettings />
                    </Route> */}
                    {/* <Redirect exact from="/" to="/vendors" /> */}

                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    )
}

export default App;