import {
    IonCol,
    IonRow,
} from '@ionic/react';

// import '../ViewPrivacy.scoped.scss';

const sectionText = {
    // color: '#F2C498',
    fontFamily: "'Karla', sans-serif",
    fontSize: '26px',
    fontWeight: 600,
    width: '100%',
}

const sectionRow = {
    marginTop: '26px',
    marginBottom: '8px',
}

const Section = ({ children }) => {
    return (
        <IonRow style={sectionRow}>
            <IonCol>
                <span
                    style={sectionText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { Section };