import {
    IonCol,
    IonRow,
} from '@ionic/react';

// import '../ViewPrivacy.scoped.scss';

const titleText = {
    // color: '#F2C498',
    fontFamily: "'Karla', sans-serif",
    fontSize: '22px',
    fontWeight: 600,
    width: '100%',
}

const titleRow = {
    marginTop: '22px',
    marginBottom: '8px',
}

const Title = ({children}) => {
    return (
        <IonRow style={titleRow}>
            <IonCol>
                <span
                    style={titleText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { Title };