import {
    IonCol,
    IonRow,
} from '@ionic/react';

// import '../ViewPrivacy.scoped.scss';

const paragraphText = {
    // color: 'white',
    fontFamily: "'Karla', sans-serif",
    fontSize: '18px',
    fontWeight: 400,
    width: '100%',
}

const paragraphRow = {
    marginTop: '8px',
    marginBottom: '8px',
}


const Paragraph = ({ children }) => {
    return (
        <IonRow style={paragraphRow}>
            <IonCol>
                <span
                    style={paragraphText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { Paragraph };