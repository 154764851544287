import React, { useEffect, useState } from 'react';

import {
    IonImg,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton
} from '@ionic/react';

import { useHistory } from "react-router-dom";

const Card = (props) => {
    const [content, setContent] = useState(undefined);
    const [height, setHeight] = useState(50);
    const history = useHistory();

    function toUrl(url) {
        history.push(url)
    }

    useEffect(() => {
        let tmpContent = []

        for (var i in props.content) {
            console.log(i, props.content.length - 1)
            tmpContent.push(<p>
                {props.content[i]}
            </p>)

            if (i !== (props.content.length - 1)) {
                tmpContent.push(<br />)

            }
        }

        // setHeight(height + (props.content.length - 1) * 20)

        setContent(tmpContent)
    }, [props.content])

    return (
        <IonCard >
            {/* style={{ 'height': `${height}vh` }} */}
            {/* <IonImg src={props.img} /> */}
            {/* style={{ opacity: '0.8' }} */}
            <img src={props.img}  /> 
            <IonCardHeader>
                <IonCardTitle style={{ textAlign: 'center', fontWeight: '400' }}>
                    {props.title}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{textAlign: 'center'}}>
            <IonButton style={{
                // position: 'absolute',
                // bottom: '16px',
                // right: '16px',
                color: '#000',
                '--background': '#56b1ba',
            }} onClick={() => (toUrl(props.url))}>
                <p style={{ color: 'black' }}>Learn More</p>
            </IonButton>
            </IonCardContent>

        </IonCard>
    )
}

export { Card };
