import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';

import {
    IonApp,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
} from '@ionic/react';

import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { Title } from '../../components/Title';

import { useHistory } from "react-router-dom";

import { deviceType } from 'react-device-detect';

function PagePractice() {
    const history = useHistory();

    function toHome() {
        history.push('/')
	}

    return (
        <IonPage fullscreen={true} id="main-content">
            <IonHeader>
                <IonToolbar style={{ paddingLeft: deviceType=="mobile"? '12px':'2rem' }}>
                    <IonButtons slot={deviceType=="mobile"? "start":"end"} style={{ paddingRight: '2rem' }}>
                        <IonButton onClick={() => (toHome())}>Besson Orthodontics</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent style={{ height: '100%' }}>
                <IonGrid style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    maxWidth: '80rem'
                }}>
                    <Section>
                        Our Practice
                    </Section>

                    <Title>
                        Our Staff
                    </Title>

                    <Paragraph>
                    Our staff is a group of highly trained and dedicated professionals focused on your orthodontic care. 
                    Our average employee has been working with Dr. Besson for over 18 years. This longevity ensures that 
                    your treatment will progress properly. You will become very comfortable with our friendly family 
                    type of atmosphere. 
                    </Paragraph>

                    <Title>
                        Independent single office
                    </Title>

                    <Paragraph>
                    Unlike many other offices, we are not owned by a larger business group focused on a profit model.
                    Rather, we are an independent office with a single location focused only on giving our patients the most
                    optimal orthodontic care. Dr. Besson coordinates and oversees all aspects of patient care.
                    </Paragraph>

                    <Title>
                    High Standards
                    </Title>

                    <Paragraph>
                    A standard of excellence in personalized orthodontic care enables us to provide the quality orthodontic
                    services our patients deserve. We provide comprehensive treatment planning and use the most up to
                    date orthodontic treatments and materials to help you achieve a beautiful new smile. 
                    We make every effort to see and care for you as soon as possible.
                    </Paragraph>

                    <Title>
                    Uncompromising Safety
                    </Title>

                    <Paragraph>
                    Infection control in our Scotch Plains orthodontic office is also very important to us. To protect our
                    patients and ourselves, we strictly maintain sterilization and cross contamination processes using
                    standards recommended by the American Dental Association (ADA), the Occupational Safety and
                    Health Administration (OSHA), and the Center for Disease Control (CDC).
                    </Paragraph>

                    <Title>
                    Training & Expertise
                    </Title>

                    <Paragraph>
                    As your orthodontic specialists, we want you to be confident knowing that we are a team of highly
                    trained and skilled orthodontic clinicians.  Our office has treated over 21,000 patients since Dr. Besson
                    Sr. opened the doors in 1968, so you can be sure you are in experienced hands.  We pride ourselves
                    in providing the care you need to keep your smile beautiful.  To give you the best possible service and
                    results, we are committed to continual education and learning.  We attend orthodontic lectures,
                    meetings, and dental conventions to stay informed of new techniques, the latest products, and the
                    newest equipment that a modern orthodontic office can utilize to provide state-of-the-art orthodontic
                    dental care.  Also, being members of various professional orthodontic &amp; dental associations helps us to
                    stay abreast of the changes and recommendations for our profession.
                    </Paragraph>

                    <Title>
                    A Positive Experience
                    </Title>

                    <Paragraph>
                    Building a foundation of trust by treating our patients as special individuals is vital to our success.
                     Dr. Besson and the staff are committed to providing you with excellent, on time, personalized
                    orthodontic care and service to make your visits to our Scotch Plains orthodontic office as comfortable
                    and pleasant as possible.
                    </Paragraph>

                    <Paragraph>
                    We thank you for allowing us to take care of your orthodontic needs and look forward to providing you 
                    with a wonderful orthodontic experience.
                    </Paragraph>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default PagePractice;
