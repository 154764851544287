import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';

import {
	IonPage,
	IonContent,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonTitle
} from '@ionic/react';

import { deviceType } from 'react-device-detect';

import officeWide from '../../assets/officeWide.jpg';
import officeTall from '../../assets/officeTallNew.jpeg';
import chair from '../../assets/long_chair.png'
import fish from '../../assets/fish.jpg'
import waiting from '../../assets/waiting.jpg'


import { Card } from '../../components/Card';

import './PageMain.css'

const data = [
	{
		title: 'Our Practice',
		url: '/practice',
		img: chair

	},
	{
		title: 'What to Expect',
		url: '/what-to-expect',
		img: fish

	},
	{
		title: 'Payment & Insurance',
		url: '/payment-and-insurance',
		img: waiting
	},
]

function PageMain() {
	const [slides, setSlides] = useState([]);

	const [size, setSize] = useState([0, 0]);

	async function scrollTo(id) {
		const element = document.getElementById(id);
		element.scrollIntoView({ behavior: "smooth" });
	}

	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}

		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		let tmpSlides = [];

		tmpSlides.push(<IonCol
			style={{ padding: '0rem', textAlign: 'left' }}
			sizeXl={1.5}
			sizeLg={1.5}
			sizeMd={0}
			sizeSm={0}
			sizeXs={0}
		/>)

		for (const i in data) {
			let elem = data[i]

			tmpSlides.push(<IonCol
				style={{ padding: '0rem', textAlign: 'left' }}
				sizeXl={3}
				sizeLg={3}
				sizeMd={12}
				sizeSm={12}
				sizeXs={12}
			>
				<Card title={elem.title} content={elem.content} img={elem.img} url={elem.url} />
			</IonCol>)
		}

		tmpSlides.push(<IonCol
			style={{ padding: '0rem', textAlign: 'left' }}
			sizeXl={1.5}
			sizeLg={1.5}
			sizeMd={0}
			sizeSm={0}
			sizeXs={0}
		/>)

		setSlides(tmpSlides)
	}, [])

	return (
		<IonPage fullscreen={true} id="main-content">
			<IonHeader>
				<IonToolbar style={{ paddingLeft: deviceType == "mobile" ? '0rem' : '2rem', }}>
					{deviceType !== "mobile" &&
						<IonButtons>
							<IonButton onClick={() => (scrollTo("doctor"))}>
								The Doctor
							</IonButton>
							<IonButton onClick={() => (scrollTo("practice"))}>
								About Us
							</IonButton>
							<IonButton onClick={() => (scrollTo("contact"))}>
								Contact
							</IonButton>
						</IonButtons>
					}

					{deviceType == "mobile" &&
						<IonGrid style={{'padding' : '0px'}}>
							<IonRow>
								<IonCol style={{ textAlign: 'center', padding: '0px' }}>
									<IonButton onClick={() => (scrollTo("doctor"))} style={{ '--color': 'black' }}>
										The Doctor
									</IonButton>
									<IonButton onClick={() => (scrollTo("practice"))} style={{ '--color': 'black' }}>
										About Us
									</IonButton>
									<IonButton onClick={() => (scrollTo("contact"))} style={{ '--color': 'black' }}>
										Contact
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					}

					{deviceType !== "mobile" &&
						<IonButtons slot="end" style={{ paddingRight: '2rem' }}>
							<IonButton onClick={() => (scrollTo('home'))}>Besson Orthodontics</IonButton>
						</IonButtons>
					}
				</IonToolbar>
			</IonHeader>

			<IonContent style={{ height: '100%' }}>
				<IonGrid style={{ height: '100%', maxWidth: '100%', padding: '0px' }}>
					<IonRow style={{ height: '100%', width: '100%' }} id='home'>
						<IonCol style={{
							height: 'auto',
							width: '100%',
							'background-position': 'center',
							'background-size': 'cover',
							'background-repeat': 'no-repeat',
							'background-image': `url(${deviceType == "mobile" ? officeTall : officeWide})`,
							'background-color': 'rgba(0, 0, 0, 0.5)',
							'min-height': '100%',
							'position': 'relative'
						}}>
							<IonButton style={{
								position: 'absolute',
								bottom: '16px',
								left: '16px',
								// width: '6rem',
								'--background': '#fff'
							}}>
								<a href="tel:908322-8433">
									<p style={{ color: 'black', fontSize: deviceType == "mobile" ? '12px' : '16px' }}>(908) 322-8433</p>
								</a>
							</IonButton>

							<IonButton style={{
								position: 'absolute',
								bottom: '16px',
								right: '16px',
								color: '#000',
								'--background': '#fff',
							}}>
								<a href='https://maps.apple.com/maps?q=1962+Westfield+Ave,+Scotch+Plains,+NJ+07076'>
									<a href='https://maps.google.com/?q=1962+Westfield+Ave,+Scotch+Plains,+NJ+07076'>
										<p style={{ color: 'black', fontSize: deviceType == "mobile" ? '12px' : '16px' }}>1962 Westfield Avenue</p>
									</a>
								</a>
							</IonButton>
						</IonCol>
					</IonRow>

					<IonRow style={{ marginTop: '3rem', marginBottom: '3rem' }} id='doctor'>
						<IonCol sizeMd={2} sizeSm={0} sizeXs={0} />
						<IonCol style={{ textAlign: 'left' }}>
							<span style={{ fontWeight: 400, fontSize: '24px', color: '#56b1ba' }}>Meet The Doctor</span>
							<br />
							<span style={{ fontWeight: 400, fontSize: '1.6rem', fontFamily: "'Lusitana', serif" }}>Dr. Frank Besson Jr, DMD</span>
							<br />
							<p style={{ marginTop: '16px' }}>
								<b>College</b> - Villanova University
							</p>
							<p style={{ marginTop: '4px' }}>
								<b>Dental School</b> - University of Pennsylvania School of Dental Medicine
							</p>
							<p style={{ marginTop: '4px' }}>
								<b>Orthodontic Residency</b> - University of Pennsylvania School of Dental Medicine
							</p>
							<p style={{ marginTop: '16px' }}>
								<b>Recipient of the Academy of Operative Dentistry Student Award</b> - Given to the
								dental student who was voted as having provided the most outstanding restorative
								care to his or her patients by the faculty at the University of Pennsylvania
								School of Dental Medicine
							</p>
							<p style={{ marginTop: '16px' }}>
								<b>Inducted into the National Dental Honor Society (OKU)</b> - Awarded to dental students
								at the top of their class by the faculty at the University of Pennsylvania
								School of Dental Medicine
							</p>
							<p style={{ marginTop: '16px' }}>
								<b>Board Certified by the American Board of Orthodontists</b>
							</p>
							<p style={{ marginTop: '16px' }}>
								<b>Holder of US Patent 8,033,829</b> - Invented the solution used by many orthodontists
								for the attachment of braces to the teeth
							</p>

						</IonCol >
						<IonCol sizeMd={2} sizeSm={0} sizeXs={0} />
					</IonRow>


					<IonRow>
						<IonCol sizeMd={2} sizeSm={0} sizeXs={0} />
						<IonCol style={{ textAlign: 'left' }}>
							<p>Dr. Besson will be able to help you make an informed decision on which type of treatment is right for you. Contact our Scotch Plains, New Jersey orthodontic office with any questions or to schedule your appointment. </p>
						</IonCol>
						<IonCol sizeMd={2} sizeSm={0} sizeXs={0} />
					</IonRow>

					<IonRow>
						<IonCol sizeMd={2} sizeSm={1} sizeXs={1} />
						<IonCol sizeMd={8} sizeSm={10} sizeXs={10}>
							<p style={{ textAlign: deviceType=="mobile" ? 'center': 'left', marginTop: '2rem' }}><b>We thank you for allowing us to take care of your orthodontic needs and look forward to providing you with a wonderful orthodontic experience.</b></p>
						</IonCol>
						<IonCol sizeMd={2} sizeSm={1} sizeXs={1} />
					</IonRow>

					<IonRow style={{ marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }} id='practice'>
						<IonCol style={{ textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}>
							<h1 style={{ fontSize: '2.4rem' }}>About Us</h1>

							<IonRow style={{ height: '90%' }} className='justify-content-center'>
								{slides}
							</IonRow>

						</IonCol>
					</IonRow>

					<IonRow style={{ 'height': '16rem', backgroundColor: '#56b1ba', margin: '0px' }} id="contact">
						<IonCol style={{ paddingLeft: deviceType=="mobile"? '16px' : '3.2rem', paddingTop: '1.6rem' }}>
							<p style={{ fontSize: deviceType=="mobile"? '14px':'24px' }}>Thank you.</p>
							<hr style={{ 'border-top': '1px solid black', marginTop: '16px', marginBottom: '16px', marginRight: '20%' }} />
							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px' }}>We really appreciate your time, and look forward to providing you with exceptional orthodontic care</p>
						</IonCol>
						<IonCol style={{ paddingLeft: deviceType=="mobile"? '0px': '3.2rem', paddingTop: '1.6rem' }}>
							<p style={{ fontSize: deviceType=="mobile"? '14px' : '24px' }}>Questions?</p>
							<hr style={{ 'border-top': '1px solid black', marginTop: '16px', marginBottom: '16px', marginRight: '20%' }} />
							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px' }}>Give us a call. We'd love to talk.</p>
							<a href="tel:908322-8433">
								<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', marginTop: '8px', color: 'black' }}>(908) 322-8433</p>
							</a>
						</IonCol>
						<IonCol style={{ paddingLeft: deviceType=="mobile"? '0px' : '3.2rem', paddingTop: '1.6rem' }}>
							<p style={{ fontSize: deviceType=="mobile"? '14px' : '24px' }}>Where to find us.</p>
							<hr style={{ 'border-top': '1px solid black', marginTop: '16px', marginRight: '20%' }} />
							<a href='https://maps.apple.com/maps?q=1962+Westfield+Ave,+Scotch+Plains,+NJ+07076'>
								<a href='https://maps.google.com/?q=1962+Westfield+Ave,+Scotch+Plains,+NJ+07076'>
									<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', marginTop: '8px', color: 'black' }}>1962 Westfield Ave <br />Scotch Plains, NJ 07076</p>
								</a>

							</a>
							{/* <p >Hours</p> */}
							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', marginTop: '16px', color: 'black' }}>Monday, Tuesday, Thursday</p>
							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', color: 'black' }}>8:00am - 5:30pm</p>

							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', marginTop: '16px', color: 'black' }}>Friday</p>
							<p style={{ fontSize: deviceType=="mobile"? '12px' : '18px', color: 'black', }}>8:00am - 4:30pm</p>
						</IonCol>

					</IonRow>

				</IonGrid>

			</IonContent>
		</IonPage>
	);
}

{/* <header className="App-header">
<img src={logo} className="App-logo" alt="logo" />
<p>
	Edit <code>src/App.js</code> and save to reload.
</p>
<a
	className="App-link"
	href="https://reactjs.org"
	target="_blank"
	rel="noopener noreferrer"
>
	Learn React
</a>
</header> */}

export default PageMain;
